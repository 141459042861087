import React from "react";
const defaultColor = "#9E9E9E";

// const SIZE = 16;

export default function TurtleIcon({
	style = { fill: defaultColor },
	...others
}) {
	return (
		<svg
			className="MuiSvgIcon-root"
			width="32.000000pt"
			height="41.000000pt"
			viewBox="0 0 198.000000 255.000000"
			{...style}
			{...others}
		>
			<g transform="translate(0.000000,255.000000) scale(0.100000,-0.100000)"
				fill="#000000" stroke="none" {...style}>
				<path d="M917 2439 c-26 -10 -96 -28 -155 -38 -125 -23 -168 -39 -217 -82 -44
-39 -65 -78 -65 -119 0 -18 -3 -30 -7 -28 -40 20 -87 39 -105 41 -18 2 -23 -5
-30 -38 -6 -27 -23 -54 -54 -85 -150 -151 -196 -233 -197 -351 0 -54 6 -79 31
-134 33 -69 118 -164 181 -201 17 -10 31 -21 31 -24 0 -3 -30 -21 -66 -40
-103 -55 -105 -60 -37 -104 44 -28 65 -36 89 -32 l31 4 -19 -36 c-28 -56 -23
-113 17 -202 22 -48 35 -93 35 -119 0 -33 5 -43 27 -58 16 -9 43 -30 62 -47
l33 -31 -4 72 c-3 59 -1 70 10 61 45 -38 51 -117 12 -153 -37 -34 -20 -136 31
-184 13 -12 47 -35 75 -51 29 -16 69 -44 89 -61 19 -17 38 -29 41 -26 3 3 -1
30 -9 61 -7 30 -10 53 -5 50 18 -11 45 -81 51 -129 8 -75 23 -100 99 -161 38
-31 74 -67 80 -80 12 -25 28 -32 28 -12 0 7 38 49 85 93 85 79 86 81 95 142 9
63 32 124 55 143 11 9 11 2 -1 -36 -22 -75 -19 -78 38 -35 29 21 71 48 93 59
103 52 147 166 90 234 -14 16 -25 41 -25 54 0 28 22 75 43 93 13 10 14 6 6
-30 -6 -23 -7 -55 -4 -71 l7 -29 23 25 c12 13 36 31 52 40 26 13 31 23 37 67
4 28 21 78 37 111 41 81 47 142 21 201 l-21 46 29 -5 c22 -5 43 3 90 32 l62
38 -28 23 c-16 13 -54 36 -85 52 l-56 28 64 49 c130 100 189 210 181 338 -6
96 -43 163 -157 287 -66 72 -90 106 -95 133 -8 41 -11 41 -93 5 l-43 -18 0 24
c0 61 -46 122 -121 160 -25 13 -97 33 -159 45 -62 12 -136 30 -164 41 -61 23
-77 23 -139 -2z m370 -86 c-4 -3 -10 -3 -14 0 -3 4 0 7 7 7 7 0 10 -3 7 -7z
m-1009 -419 c-17 -52 -18 -67 -8 -105 15 -56 75 -114 132 -130 24 -6 49 -13
57 -16 10 -2 3 -13 -22 -34 -43 -36 -46 -45 -13 -53 22 -5 24 -10 22 -76 -1
-70 -1 -71 -26 -65 -39 10 -111 55 -160 101 -94 87 -124 206 -78 306 22 46
104 146 113 136 2 -1 -6 -30 -17 -64z m1510 -54 c22 -37 27 -57 27 -115 0 -59
-5 -79 -29 -123 -45 -81 -151 -168 -228 -187 -27 -7 -28 -6 -22 23 4 17 1 45
-5 63 -13 37 -1 59 31 59 16 0 17 3 8 21 -6 11 -25 28 -42 39 l-30 18 58 17
c71 20 116 52 138 98 20 44 21 113 1 160 -8 20 -15 40 -15 44 0 15 83 -74 108
-117z m-1127 -259 c16 -102 36 -138 72 -132 18 3 -10 -117 -36 -154 -14 -18
-15 -17 -32 16 -10 19 -38 52 -61 75 -38 34 -44 46 -44 80 0 21 9 66 20 99 11
33 22 73 25 89 6 29 6 29 26 10 12 -12 23 -45 30 -83z m713 58 c4 -23 16 -64
28 -91 31 -73 22 -122 -35 -173 -25 -22 -51 -53 -59 -68 -17 -35 -28 -25 -52
46 -22 65 -21 97 2 97 23 0 48 55 56 123 6 46 32 107 47 107 3 0 9 -19 13 -41z
m-824 -367 c26 -26 50 -96 37 -108 -9 -9 -84 34 -91 51 -8 22 -8 70 1 78 12
12 24 7 53 -21z m350 -12 c73 -54 110 -54 178 -1 27 22 53 37 56 33 4 -3 4
-43 0 -89 -4 -47 -3 -83 2 -83 5 0 27 34 48 75 22 42 44 74 50 72 44 -15 13
-267 -43 -345 -22 -32 -155 -92 -202 -92 -28 0 -139 41 -176 66 -34 22 -58 81
-81 198 -16 80 -18 105 -9 132 7 18 17 34 22 36 6 2 26 -28 47 -67 50 -96 62
-97 53 -4 -8 83 -8 80 -6 97 1 17 1 17 61 -28z m588 1 c4 -36 -15 -64 -60 -87
-39 -20 -41 -18 -35 29 4 30 14 50 37 70 38 34 54 31 58 -12z m52 -122 c0 -6
-18 -28 -39 -48 -22 -20 -41 -46 -43 -58 -3 -22 -4 -21 -24 10 -12 17 -25 42
-29 55 -6 22 -5 23 27 17 23 -4 44 0 68 13 19 11 36 20 38 21 1 0 2 -4 2 -10z
m-1056 -15 c27 -13 40 -14 65 -6 40 14 40 3 1 -65 -33 -57 -44 -67 -34 -29 4
19 -3 32 -35 64 -56 55 -56 63 3 36z m419 -369 c83 -15 92 -15 193 4 57 11
110 18 117 15 7 -2 21 -18 31 -35 16 -28 16 -33 1 -78 -9 -27 -25 -57 -37 -67
l-20 -19 7 45 c4 25 14 53 23 63 24 27 12 28 -61 2 -84 -29 -132 -65 -148
-110 -7 -19 -16 -35 -19 -35 -3 0 -14 18 -24 40 -19 43 -61 73 -155 110 -58
22 -73 20 -49 -7 8 -10 18 -37 22 -60 5 -34 3 -41 -8 -37 -21 8 -59 102 -52
130 10 37 44 67 69 61 12 -3 61 -13 110 -22z m157 -279 c0 -2 3 -11 6 -20 5
-14 -5 -16 -75 -16 -63 0 -81 3 -81 14 0 8 6 17 13 19 15 7 137 9 137 3z m17
-73 c-4 -3 -7 0 -7 7 0 7 3 10 7 7 3 -4 3 -10 0 -14z"/>
			</g>
		</svg >
	);
}
export function OldTurtleIcon({ style = { fill: defaultColor }, ...others }) {
	return (
		<svg
			width="48px"
			height="48px"
			viewBox="0 0 48 48"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...others}
		>
			<g
				id="inactive"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
				opacity="0.66"
			>
				<g
					id="Turtle"
					transform="translate(1.250000, 0.000000)"
					fill-rule="nonzero"
					{...style}
				>
					<path
						d="M6.07880435,3.86666667 C5.58695652,3.24777777 5.03804348,3.41222223 4.68478261,2.70916667 C4.11413044,1.57444444 5.22744565,-0.231666667 6.39130438,0.0244444444 C7.00978263,0.161666667 7.85271738,1.65888889 7.63858694,2.44722222 C7.42771738,3.22777777 6.5692935,3.3 6.07880435,3.86666667 M3.03804348,4.16944444 C2.85407609,4.83611111 2.17309783,5.32555556 2.11304348,6.03555556 C1.21358696,5.75861111 -0.318206522,3.68277778 1.08858696,2.95972223 C1.80380435,3.17472223 2.38913044,3.76527778 3.03804348,4.16972223"
						id="Shape"
					/>
					<path
						d="M5.30978261,3.81111111 C5.63423913,3.98 5.82255435,4.54388889 6.10733696,4.82888889 C6.49592394,4.48 6.5557065,4.01305556 7.06440219,3.70666667 C9.39211956,3.74194444 10.6717391,7.47194444 10.2736413,9.39555556 C9.8125,11.6213889 7.09239131,13.0794444 5.70516304,14.7883333 C5.76766304,12.5458333 2.45081522,12.1205556 2.06521739,9.3125 C1.75190218,7.03472225 2.55027174,4.08 5.30978261,3.81055556 M3.53885869,7.93611113 C4.22527174,7.38527775 5.24456522,6.81833331 6.07826087,6.79444444 C6.10869565,7.26944444 6.20244565,7.76666669 6.10054348,8.19388888 C5.33858696,8.60416669 4.32717391,8.92638887 4.07146739,9.51833331 C4.71141304,9.14611112 5.51711956,8.535 6.18016304,8.58888888 C6.11929348,9.10361112 6.27472825,9.69583331 6.07228261,10.1611111 C5.59402174,10.1861111 4.92554348,10.7077777 4.69048913,11.0572223 C4.93505435,11.0872222 5.69538044,10.3666667 6.11114131,10.4675 C6.29103262,11.1425 5.87282609,11.9911111 6.21032609,12.4791667 C6.28315219,11.7730556 6.4125,11.0875 6.49836956,10.3852777 C6.87934781,10.5241667 7.2625,10.6622222 7.64592394,10.7952778 C7.36603262,10.2202777 6.83423912,10.3786111 6.50380437,9.94805556 C6.61086956,9.49861113 6.50706519,8.97305556 6.69510869,8.5525 C7.24184781,8.69694444 7.7744565,8.88416669 8.26739131,9.18861112 C7.8182065,8.47888888 7.10597825,8.53583331 6.57364131,8.06916669 C6.73125,7.63861112 6.52527175,7.07472225 6.6375,6.6275 C7.36630438,6.66027775 7.97092394,7.05527775 8.6380435,7.26916669 C8.05543481,6.20944444 6.6557065,6.80083331 6.39809781,5.44361111 C5.84918478,5.54 6.06521739,5.91027777 6.00244565,6.18027778 C4.97445652,6.64222225 3.59130435,7.36694444 3.53967391,7.935"
						id="Shape"
					/>
					<path
						d="M11.2247283,2.85277777 C12.3214674,3.93611111 11.2546196,5.16527777 10.2391304,5.94083333 C9.91902175,5.20333333 9.54076088,4.63305556 9.10869562,4.21944444 C9.72065219,3.75555556 10.3505435,2.98722222 11.2247283,2.85277777 M2.33559783,11.24 C2.78070652,11.6844444 3.15543478,12.3333333 3.68559783,12.5288889 C2.83369565,13.1261111 2.67201087,13.9722222 1.64646739,14.5080556 C0.994565219,14.3175 0.407608696,13.9422223 0,13.0388889 C0.241847826,12.2472223 1.27445652,11.7380556 2.33505435,11.2388889 M12.3600544,12.9222222 C12.1576087,13.6083333 11.2807065,14.3180556 10.4997283,14.3797223 C9.90108694,13.6858333 9.30760869,12.9813889 8.64646738,12.4722222 C9.19809781,12.1091667 9.61385869,11.6961111 9.86114131,11.2238889 C10.8168478,11.4308333 11.7342391,11.7488889 12.3597826,12.9227777"
						id="Shape"
					/>
				</g>
			</g>
		</svg>
	);
}
