import TurtleIcon from "images/Turtle.icon";

const howard_blue = {
	50: "#e2eff4",
	100: "#b7d6e5",
	200: "#8dbdd4",
	300: "#66a3c2",
	400: "#4b91b8",
	500: "#003a63",
	600: "#2675a3",
	700: "#196493",
	800: "#0f5582",
	900: "#003a63",
	A100: "#8C9EFF",
	A200: "#536DFE",
	A400: "#3D5AFE",
	A700: "#304FFE",
	contrastDefaultColor: "light",
};
const brand = howard_blue;

const config = {
	brand,
	name: "Howard University",
	apiUrl: process.env.REACT_APP_API_URL || "/api",
	basename: process.env.REACT_APP_BASENAME || "/",
	documentationURL: "https://terpfootprints.umd.edu/docs",
	contact: {
		email: "city@umd.edu",
		name: "City@UMD",
	},
	surveyURL:
		"https://docs.google.com/forms/d/e/1FAIpQLSceY3Vt0cJLZ2ngMeeQWWVjDXAZByaAKU_ZHmV4QBJu-UH2gg/viewform",
	loadingIcon: TurtleIcon,
	organization: {
		name: "Howard University",
		location: "Washington, DC",
		nameShort: "HU",
		type: "Campus",
		orgIcon: "account_balance",
		logoBanner: "images/logos/umd_logo.png",
		logo: "images/logos/umd_icon_logo.png",
		reportLogo: "images/logos/umd_globe.png",
		orgImage: "images/logos/umd_campus.jpg",
		fiscalYearMonthStart: 6,
		supportEmail: "support@buildscianalyst.com",
	},
	department: {
		leader: "Director",
		name: "Facilities Management: Engineering & Energy",
		leaderImage: "images/reports/director.png",
		groupImage: "images/reports/FM_DepartmentLogo.png",
		address: `7757 Baltimore Ave. College Park, MD 20742`,
		contact: { phone: "301-405-2222", email: "fmcrc@umd.edu" },
	},
	auth: {
		thirdPartyLogin: true,
		thirdPartyLoginName: "UMD ID",
		thirdPartyApiPath: "/auth/umd-login",
		thirdPartyLoginPath: () =>
			`https://shib.idm.umd.edu/shibboleth-idp/profile/cas/login?service=${encodeURIComponent(
				window.location.origin + window.location.pathname
			)}`,
		levels: [
			{ id: "public", label: "Public Users" },
			{ id: "general", label: "General Users" },
			{ id: "advanced", label: "Advanced Users" },
			{
				id: "facilities",
				label: "Facility Management Users",
			},
			{ id: "admin", label: "Admin Users" },
			{ id: "dev", label: "Admin Users" },
		],
	},
	zones: [
		{ name: "FM Administrators", zone: "0", scope: "admin", visible: true },
		{ name: "FM Operators", zone: "1", scope: "fm", visible: true },
		{ name: "FM Operators", zone: "2", scope: "fm", visible: true },
		{ name: "FM Operators", zone: "3", scope: "fm", visible: true },
		{ name: "FM Operators", zone: "4", scope: "fm", visible: true },
		{ name: "FM Operators", zone: "5", scope: "fm", visible: true },
		{ name: "FM Operators", zone: "6", scope: "fm", visible: true },
		{ name: "Researchers", zone: "7", scope: "general", visible: true },
		{ name: "FM Operators", zone: "8", scope: "fm", visible: true },
		{ name: "UMD Faculty and Students", zone: "9", scope: "general", visible: true },
		{ name: "FM Managers", zone: "10", scope: "fm", visible: true },
		{ name: "HVAC/FM Group", zone: "11", scope: "fm", visible: true },
		{ name: "UMD Administration", zone: "12", scope: "general", visible: true },
		{ name: "BuidSci Admin", zone: "100", scope: "admin", visible: false },
	],
	scopes: [
		"general", // customergeneral
		"admin", // customeradmin
		"fm", // customerfm
		// "topadmin" // buildscitopadmin
		"customerservice", // buildscicustomerservice // customersupport
	],
	scopesToCache: [
		{ scope: "general", cache: "allbyone", show: true },
		{ scope: "fm", cache: "allbytwo", show: true },
		{ scope: "admin", cache: "allbythree", show: true },
		{ scope: "topadmin", cache: "allbyme", show: false },
		{ scope: "customerservice", cache: "allbycustomer", show: true },
	],
	scopeTopAdmin: { name: "topadmin", zone: "100" },
	constType:
		{ "electric": 3.41214, "steam": 1.013 }
	,
	map: {
		defaultCenter: [-76.96225948, 38.97420956],
		icon: TurtleIcon,
		// icon: "building-15",
		// iconPath: "/images/icons"
		// mapIconPath: "/images/icons",
		// mapIcon: "building-15"
	},
	semester: [
		{ semester1: { name: "Winter/Spring", title: "Winter/Spring YYYY", period: { start: "01/01", end: "05/31" } }, },
		{ semester2: { name: "Summer", title: "Summer YYYY", period: { start: "06/01", end: "08/31" } }, },
		{ semester3: { name: "Fall", title: "Fall YYYY", period: { start: "09/01", end: "12/31" } }, },
	],
	baselineYear: new Date(2015, 0),
	host: {
		logo: "/images/host/icon.png",
	},
	userAnalytics: {
		datastudio: {
			iframe: `<iframe width="1150" height="1150" src="https://datastudio.google.com/embed/reporting/93a6af70-4c76-4e1f-bfcb-b22e1b3e6c95/page/6zg4C" frameborder="0" style="border:0" allowfullscreen>`,
			link:
				"https://datastudio.google.com/embed/reporting/93a6af70-4c76-4e1f-bfcb-b22e1b3e6c95/page/6zg4C",
		},
	},
	cookieConsent: {
		name: "cookieconsent_status_howard",
		path: "/",
		domain: "buildscianalyst.com",
		expiryDays: 356,
		secure: true,

		header: 'Cookies used on the website!',
		message: 'This website uses cookies to improve your experience and make web applications fully operational.',
		dismiss: 'Got it!',
		allow: 'Allow cookies',
		deny: 'Decline',
		link: 'Learn more',
		href: 'https://www.allaboutcookies.org',
		hrefDeny: 'https://howard.edu/',
		close: '&#x274c;',
		policy: 'Cookie Policy',
		target: '_blank',
	},
	costOfDoingNothing: {
		electricity: 0.12,
		electricityThirdParty: 0.1,
		electricityForCooling: 0.12,
		steam: 0.03,
		ng: 0.75,
		hw: 0.13,
	},
};

document.title = config.name;
export default config;
